import { FunctionComponent, useState } from "react"
import { TextInput } from "../inputs"
import { UserTypes } from "../../constants";
import { useForm } from "react-hook-form";
import { useUser } from "../../hooks/useUser";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import { changeCreditPassword } from "../../utils/SoftPullAPI";
import Button, { BUTTON_THEMES } from "../Button";
import { forgotPassword, resetPassword } from "../../utils/Cognito";
import { IForgotPasswordForm } from "./ResetPasswordsForm";
import { useNavigate } from "react-router-dom";

const ResetCBCPassword: FunctionComponent = () => {
    const [showCBCPasswordReset, setShowCBCPasswordReset] = useState<boolean>(false);
    const [showReset, setShowReset] = useState<boolean>(false);
    
    const { user } = useUser();
    const navigate = useNavigate();
    const { updateNotification } = useDashboard();
    const {
        register,
        handleSubmit,
        formState: { errors }
   } = useForm({
        defaultValues: {
             prevPassword: '',
             newPassword: '',
             email: '',
             code: ''
        }
   });
   
   const toggleCBCPasswordReset = () => setShowCBCPasswordReset(!showCBCPasswordReset);

   const submit = async (values: { prevPassword: string, newPassword: string, email: string, code: string }) => {
        try {
             const uid = (user.userType.toLowerCase() === UserTypes.OWNER.toLowerCase() && process.env.REACT_APP_CV_MASTER_ID) ? process.env.REACT_APP_CV_MASTER_ID : user.userId.substring(0, 19)
             // @ts-ignore
             const cus_id = user.userType.toLowerCase() === UserTypes.OWNER.toLowerCase() ? process.env.REACT_APP_CV_CUST_ID : user.metadata?.cus_id

             const resultCBC = await changeCreditPassword({
                  uid,
                  cus_id
             }, values.prevPassword, values.newPassword);

            const resultCognito = await resetPassword(values.email, values.newPassword, values.code);

            if (resultCognito.$metadata.httpStatusCode === 200) {
                navigate('/login');
            } else {
                updateNotification({
                    theme: NotificationThemes.FAIL,
                    message: 'Failed to Reset Password'
               })
            }
        } catch (error) {
             updateNotification({
                  theme: NotificationThemes.FAIL,
                  message: 'Failed to Reset Password'
             })
        } finally {
             toggleCBCPasswordReset();
        }
   }

   const submitForgotPassword: (values: any) => void = async ({ email }) => {
        try {
            const result = await forgotPassword(email);

            if (result.$metadata.httpStatusCode === 200) {
                setShowReset(true);
            }
        } catch (error) {
            console.log({error})
        }
    }
   
    return (
        <div>
            <p
                className="w-fit text-primary cursor-pointer hover:underline"
                onClick={toggleCBCPasswordReset}
            >
                Reset CBC Password
            </p>
            {
            (showCBCPasswordReset && !showReset) ? (
                <div className="max-w-[350px] mt-4">
                    <TextInput
                        label='Email'
                        id='email'
                        placeholder='Enter email...'
                        register={register}
                        containerStyles='mb-4'
                    />
                    <Button
                        text='Submit'
                        onClick={handleSubmit(submitForgotPassword)}
                        styles='self-center w-[50%] mb-8'
                    />
                </div>
            )
            
            : (showCBCPasswordReset && showReset) ? (
                <div className="max-w-[350px] mt-4">
                    <form>
                            <TextInput
                                id="prevPassword"
                                label="PrevPassword"
                                placeholder="Previous password..."
                                password
                                register={register}
                            />
                            <TextInput
                                id="newPassword"
                                label="NewPassword"
                                placeholder="New password..."
                                password
                                register={register}
                            />
                            <TextInput
                                label='Code'
                                id='code'
                                placeholder='Confirmation Code...'
                                containerStyles='mb-4'
                                register={register}
                            />
                    </form>
                    <div className="flex justify-between items-center mt-6">
                            <Button
                                text="Cancel"
                                onClick={toggleCBCPasswordReset}
                                theme={BUTTON_THEMES.INVERSE}
                            />
                            <Button text="Submit" onClick={handleSubmit(submit)} />
                    </div>
                </div>
            ) : <></>
            }
        </div>
    )
}

export default ResetCBCPassword;