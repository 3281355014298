import axios from "axios";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const getReferralAccounts = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/referral-accounts/all`);
  
        return result?.data?.result?.Items;
    } catch (error) {
        throw error;
    }   
}

export default {
    getReferralAccounts
}