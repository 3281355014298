import { FunctionComponent } from "react";

interface IBadgeProps {
  text: string;
  className?: string; // Add support for custom class names
}

const Badge: FunctionComponent<IBadgeProps> = ({ text, className = "" }) => (
  <div
    className={`w-[200px] h-[40px] bg-primary text-white uppercase rounded-md text-center flex items-center justify-center text-md ${className}`}
  >
    <p>{text}</p>
  </div>
);

export default Badge;

