import { FunctionComponent } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer";
import PageTitle from "../components/PageTitle";
import { useUser } from "../hooks/useUser";
import useCustomer from "../hooks/useCustomer";
import Loading from "../components/Loading";
import ManagePaymentMethod from "../components/billing/ManagePaymentMethod";
import { ACCESS_LEVEL } from "../constants";
import OneTimePayment from "../components/billing/OneTimePayment";
import SubscriptionOptions from "../components/billing/SubscriptionOptions";

const Billing: FunctionComponent = () => {
     const { user } = useUser();
     //@ts-ignore
     const { stripeId } = user.metadata;

     const { loading, paymentMethod } = useCustomer();


     return (
          <DashboardPageContainer>
               <PageTitle title="Billing" />
               {
                    (!stripeId && !loading) ? <p>Please Contact Support About Configuring Account</p>
                    : loading ? <Loading /> 
                    : (
                         <div className="flex align-center justify-between">
                              {
                                   user.metadata.accessLevel <= ACCESS_LEVEL.CVA ?
                                        <OneTimePayment />
                                        : <SubscriptionOptions />
                              }

                             <ManagePaymentMethod paymentMethod={paymentMethod} />
                         </div>
                    )
               }
          </DashboardPageContainer>
     )
}

export default Billing;