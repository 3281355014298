import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import { ACCESS_LEVEL } from '../constants';
import { supportHelpers } from '../utils/ClientVitalsAPI';

const useSupport = () => {
    const { user: { metadata: { accessLevel }, userId } } = useUser();

    const [tickets, setTickets] = useState<Record<any, any>[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchTickets = async () => {
        if (!loading) setLoading(true);
        
        try {
            const fetchedTickets: Record<any, any>[] = await supportHelpers.getSupportTickets();

            setTickets(fetchedTickets);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userId && (accessLevel === ACCESS_LEVEL.OWNER || accessLevel === ACCESS_LEVEL.CVA)) {
            fetchTickets()
        }
    }, []);

    return { tickets, loading, error, fetchTickets } 
}

export default useSupport;