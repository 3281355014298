import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Companies, CompanyId } from '../pages/companies';

const CompanyRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Companies />} />
        <Route path=':id' element={<CompanyId />} />
    </Routes>
);

export default CompanyRoutes;