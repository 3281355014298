import { FunctionComponent, useState } from "react";
import Button from "../../Button";
import Modal from "../Modal";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import { sendgridHelpers } from "../../../utils/ClientVitalsAPI";
import useClients from "../../../hooks/useClients";
import { useUser } from "../../../hooks/useUser";
import { UserTypes } from "../../../constants";
import CreditPullFormInfo from "./CreditPullFormInfo";


const SendApplicationInviteLinkModal: FunctionComponent<{clientId: string}> = ({ clientId }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [applicantCount, setApplicantCount] = useState<Number>(1);
    const { getClientById } = useClients();
    const { updateNotification } = useDashboard();
    const { user } = useUser();
    let company: string | undefined;
    let member: string | undefined;

    if (user.userType.toLowerCase() === UserTypes.COMPANY.toLowerCase()) {
        company = user.userId
        member = user.userId
    }
    if (user.userType.toLowerCase() === UserTypes.MEMBER.toLowerCase()) {
        //@ts-ignore
        company = user.userInfo.companyId
        member = user.userId
    }
    if (user.userType.toLowerCase() === UserTypes.MANAGER.toLowerCase()) {
        company = process.env.REACT_APP_SUB_ACCOUNT
        member = user.userId
    }
    if (user.userType.toLowerCase() === UserTypes.OWNER.toLowerCase()) {
        company = user.userId
        member = user.userId
    }

    
    const toggleModal = () => setOpen(!open);
    const submit = async () => {
        const client = getClientById(clientId)

        if (!client) return;

        try {
            const payload = {
                name: client?.userInfo.name,
                email: client?.userInfo.email,
                formLink: `${process.env.REACT_APP_URL_BASE}/application/credit/${client?.userId}?i=${company}&e=${member}&ac=${applicantCount || 1}`
            }

            await sendgridHelpers.sendCreditApplicationLink(payload);
        } catch (error) {
            updateNotification({
                message: 'Email Failed To Send',
                theme: NotificationThemes.FAIL
            })
        } finally {
            toggleModal();
        }
    }

    return (
        <div>
            <Button text="New" onClick={toggleModal} />
            <Modal 
                open={open} 
                title="Send Email Form For Credit Pull?"
                minHeight="190px"
                content={
                    <CreditPullFormInfo
                        onCancel={toggleModal}
                        submit={submit}
                        setCount={(value: string) => setApplicantCount(Number(value) || 1)}
                    />
                }
                onClose={toggleModal} 
                styles="h-fit w-[450px]"
            />
        </div>
    )
}

export default SendApplicationInviteLinkModal;