import { FunctionComponent } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import { useLocation } from "react-router-dom";
import useCompanies from "../../hooks/useCompanies";
import { useUser } from "../../hooks/useUser";
import SquareTile from "../../components/tiles/SquareTile";
import Loading from "../../components/Loading";
import CompanyInfoTile from "../../components/companies/CompanyInfoTile";
import CompanyApplicationTile from "../../components/companies/CompanyApplicationTile";

const CompanyId: FunctionComponent = () => {
    const { user: { userId } } = useUser();
    const { getCompanyById, getApplicationById } = useCompanies(userId);
    const { pathname } = useLocation();

    const pathArray = pathname.split('/');
    const companyId = pathArray[pathArray.length - 1];
    const company = getCompanyById(companyId);
    const application = getApplicationById(companyId);

    return (
        <DashboardPageContainer>
            <PageTitle title={`${company?.userInfo.name}`} />

            <div className="grid grid-cols-2 gap-20">
                <SquareTile>
                 {
                    !company ? <Loading />
                        : <CompanyInfoTile company={company} />
                 }   
                </SquareTile>

                <SquareTile>
                {
                    !application ? <Loading />
                        : <CompanyApplicationTile application={application} />
                }
                </SquareTile>
            </div>
        </DashboardPageContainer>
    );
}

export default CompanyId;