import { creditHelpers } from "./ClientVitalsAPI";
import { 
    parseStringToObject,
    extractAmountPastDue,
    extractCreditTypes,
    extractInqueryDates,
    extractJudgments,
    extractLateAndCollections,
    extractUtilization,
    extractWarnings
} from './CreditExtractions';

export const parseCreditReport = async (creditReport: Record<any, any>, setScoreCardReports: Function) => {
    if(!creditReport) return;

    const cbcReport = creditReport["XML_INTERFACE"]["CREDITREPORT"]["REPORT"]["_cdata"];
    const parser = new DOMParser();
    const doc = parser.parseFromString(cbcReport, 'text/html')
    
    const scorecards = doc.querySelector('.score_cards')?.children
    
    if(scorecards) {
        try {
            const scoreLength = scorecards.length;
            const scoreObjs = [];

        for(let i = 0; i < scoreLength; i++) {
            const card = scorecards[i];
            const score = card.querySelector('.score')?.textContent?.trim() || 'N/A';
            const type = card.querySelector('.score_name')?.textContent?.trim() || 'N/A';
            //@ts-ignore
            const image = card.querySelector('.bureau_image')?.childNodes[1]?.src
            const guid = card.querySelector('.score_menu .view')?.className.split(' ')[2];
      
            const reportLink = process.env.REACT_APP_ENV === 's' ? `https://dev.cbcecredit.com/capp/recall.php?GUID=${guid}` : `https://www.creditbureauconnection.com/capp/recall.php?GUID=${guid}&uid=vcc4500109`
            
            const report = await creditHelpers.fetchReport(reportLink);

            scoreObjs.push({
                score,
                type,
                image,
                reportLink,
                report
            });
        }
          
            setScoreCardReports(scoreObjs)
        } catch (error) {
            console.log(error)
        }
    }
}

export const analyzeReport = async (iframeRefReport: any, setReportSummary: Function) => {
    //@ts-ignore
    const iframeDocument = iframeRefReport.current?.contentDocument || iframeRefReport.current?.contentWindow.document;
    
    const elements = iframeDocument.querySelectorAll('table');

    let amountPastDue;
    let scores: Record<any, any>[] = [];
    let ninetyDays;
    let sixtyDays;
    let thirtyDays;

    let availableCredit;
    let collectionItems;
    let warnings;
    let judgements;

    let revolvingCredit = false;
    let installmentCredit = false;
    let inquiriesDates: Date[] = [];

    let scoreCardCount = 1;
    elements.forEach((element: any) => {
        if(element.innerText) {
            if(element.innerText.toLowerCase().includes('scorecard:')) {
                if(scoreCardCount === 1) scoreCardCount = 2;
                else if (scoreCardCount === 2) {
                    scoreCardCount = 3;
                    const cleanedString = element.innerText.toLowerCase().substring(11).replaceAll(/(\t\n|\n|\t)/gm, " ").replace(/\s+/g, ' ');
                    
                    const stringArray = cleanedString.split('scorecard:').filter((_: any, index: number) => index !== 0);
                 
                    stringArray.forEach((card: string) => {
                        const cardString = 'scorecard:' + card;

                        const cardObj = parseStringToObject(cardString);

                        scores.push(cardObj)
                    })
                }
            }

            const cases = [
                { match: 'amount past due', action: () => amountPastDue = extractAmountPastDue(element) },
                { match: '90+ days', action: () => {
                    const result = extractLateAndCollections(element);
                    ninetyDays = result.ninety;
                    sixtyDays = result.sixty;
                    thirtyDays = result.thirty;
                    collectionItems = result.collection;
                }},
                { match: 'available %', action: () => availableCredit = extractUtilization(element) },
                { match: 'total # of jdgs', action: () => judgements = extractJudgments(element) },
                { match: 'warning messages', action: () => warnings = extractWarnings(element) },
                { match: 'trade lines\nhide', action: () => {
                    const result = extractCreditTypes(element);
                    revolvingCredit = result.revolving;
                    installmentCredit = result.installment;
                }},
                { match: 'inquiries\nhide\n\n\n', action: () => extractInqueryDates(element, inquiriesDates) }
            ];
            
            // Execute the matching case
            for (const cas of cases) {
                if (element.innerText.toLowerCase().includes(cas.match)) {
                    cas.action();
                    break; // Exit loop after matching
                }
            }
        }
    });

    setReportSummary({
        amountPastDue,
        ninetyDays,
        sixtyDays,
        thirtyDays,
        scores,
        availableCredit,
        collectionItems,
        judgements,
        warnings,
        revolvingCredit,
        installmentCredit,
        inquiriesDates
    })
};