import React, { FunctionComponent } from "react";
import Button, { BUTTON_THEMES } from "../../Button";

interface IFeedbackFormProps {
    onCancel: () => void;
    onSubmit: () => void;
    feedback: string;
    setFeedback: (value: string) => void;
}
const FeedbackForm: FunctionComponent<IFeedbackFormProps> = ({ onCancel, onSubmit, feedback, setFeedback }) => {
    console.log("Form is rendering with props:", {
        feedback, onCancel, onSubmit, setFeedback,
    });

    return (
        <div className="p-4 bg-white rounded-md shadow-sm">
            <div className="w-[80%] mx-auto">
                <label
                    htmlFor="feedbackTextarea"
                    className="text-sm text-gray-600 block mb-2"
                >
                    How did this experience affect your workflow?
                </label>
                <textarea
                    id="feedbackTextarea"
                    className="shadow-sm bg-gray-100 border border-primary rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:ring-2 focus:ring-primary mb-2"
                    rows={4}
                    placeholder="Enter feedback..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            </div>

            <div className="flex justify-center gap-40 mt-4">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Submit" onClick={onSubmit} />
            </div>
        </div>
    );
};

export default FeedbackForm;
