import { FunctionComponent, useState } from "react";
import { ReactComponent as ActionMenuIcon } from '../../assets/action-menu-icon.svg';
import { MenuAction } from './types';
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import { Link } from "react-router-dom";

interface IActionMenuProps {
    actions: MenuAction[]
    record?: Record<string, any>
}

const ActionMenu: FunctionComponent<IActionMenuProps> = ({ actions, record }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { updateNotification } = useDashboard();

    return (
        <div className="cursor-pointer relative flex items-center justify-center">
            <ActionMenuIcon className="h-[16px] w-[16px]" onClick={() => setOpen(!open)} />

            <ul className={`${open ? 'block' : 'hidden'} rounded-md absolute top-5 right-0 z-50 text-sm border border-primary bg-secondary-light`}>
                {
                    actions.map((option, index) => ( 
                    <Link to={option.navigate ? `${option.linkTo}/${record?.userId}` : ''}>
                        <li key={option.label}
                            className={`flex items-center px-8 py-1 border border-primary border-t-0 border-l-0 border-r-0 text-center ${index === actions.length - 1 ? 'border-0' : ''}`}
                            onClick={async () => { 
                                try {
                                    await option.action({ id: record?.userId, ...record });
                                    setOpen(false);
                                    updateNotification({
                                        message: "Action Success",
                                        theme: NotificationThemes.SUCCESS
                                    })
                                } catch (error) {
                                    updateNotification({
                                        message: "Action Failed",
                                        theme: NotificationThemes.FAIL
                                    })
                                }
                            }}
                        >
                            {option?.Icon && <option.Icon className="h-4 w-4 mr-2"/>}
                            {option.label}
                        </li>
                    </Link>
                    ))
               }
            </ul>
        </div>
    );
}

export default ActionMenu;