import { FunctionComponent, useState, useEffect } from "react";
import { companyHelpers } from "../../utils/ClientVitalsAPI";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import { InviteCompanyModal } from "../../components/modals";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import Loading from "../../components/Loading";
import { companyTableActions } from "../../components/actionMenu/types";

const Companies: FunctionComponent = () => {
    const [companies, setCompanies] = useState<any[]>([]); 
    const { updateNotification } = useDashboard();

    const fetchCompanies = async () => {
        try {
            const fetchedCompanies = await companyHelpers.getCompanies();
            setCompanies(fetchedCompanies || []); 
        } catch (error) {
            setCompanies([]); 
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: "Failed To Fetch Companies",
            });
        }
    };

    const columnHeaders: string[] = [
        "checkbox",
        "name",
        "email",
        "phone",
        "city",
        "state",
        "joined",
        "actions",
    ];

    const formattedCompanies: Record<string, any>[] = companies
        ?.filter((company: any) => !company.metadata.archived) 
        ?.map((user: any) => ({
            name: user.userInfo.name,
            email: user.userInfo.email,
            phone: user.userInfo.phone,
            city: user.userInfo.city,
            state: user.userInfo.state,
            userId: user.userId,
            joined: user.metadata.createdAt?.split(",")[0],
        }));

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <DashboardPageContainer>
            <PageTitle title="Companies" />
            <div className="flex justify-end items-center space-x-4 mb-4">
                <InviteCompanyModal />
            </div>
            {companies.length === 0 ? (
                <Loading />
            ) : (
                <Table
                    columnHeaders={columnHeaders}
                    records={formattedCompanies}
                    tableActions={companyTableActions}
                    linkBase="/dashboard/companies"
                />
            )}
        </DashboardPageContainer>
    );
};

export default Companies;

