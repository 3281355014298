import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import { ACCESS_LEVEL } from '../constants';
import { Manager } from '../constants/managers';
import { managerHelpers } from '../utils/ClientVitalsAPI';

const useManagers = (userId: string) => {
    const { user: { metadata: { accessLevel } } } = useUser();

    const [managers, setManagers] = useState<Manager[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchManagers = async () => {
        if (!loading) setLoading(true);
        
        try {
            const fetchedManagers: Manager[] = await managerHelpers.getManagers(accessLevel);

            setManagers(fetchedManagers);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userId && accessLevel === ACCESS_LEVEL.OWNER) {
            fetchManagers()
        }
    }, []);

    return { managers, loading, error, fetchManagers } 
}

export default useManagers;