import { useState, FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "../inputs";
import { clientHelpers } from "../../utils/ClientVitalsAPI";
import { IClientInvitationForm } from "../../constants/clients";
import { addCreditUser } from "../../utils/SoftPullAPI";
import convert from 'xml-js';
import ClientInvitationSchema from "./ValidationScehma/ClientInvitationSchema";
import DashboardPageContainer from "../DashboardPageContainer";
import PageTitle from "../PageTitle";
import Button from "../Button";
import axios from "axios";

const ClientInvitationForm: FunctionComponent = () => {
    const [error, setError] = useState<string | null>(null);
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const company = params.get('fi') || '';
    const member = params.get('fe') || '';
    const baseName = params.get('name')?.replaceAll('-', ' ') || '';
    const email = params.get('email') || '';

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: baseName,
            email: email,
            companyId: company,
            memberId: member,
            birthdate: ''
        },
        resolver: yupResolver(ClientInvitationSchema)
    });

    const submit = async (values: IClientInvitationForm) => {
        try {
            const newClient = clientHelpers.formatClient(values, company, member);

            const result = await clientHelpers.addClient(newClient);
            const ipResult = await axios.get('https://api.ipify.org?format=json');

            if (result.status === 201 || result.status === 200) {
                const cbcResult = await addCreditUser({
                    uid: result.data.result.clientId,
                    name: newClient.userInfo.name,
                    email: newClient.userInfo.email,
                    password: values.password,
                    ip: ipResult.data.ip
                });
               
                const result2 = convert.xml2js(cbcResult, { compact: true });
                
                newClient.userId = result.data.result.clientId;

                //@ts-ignore
                newClient.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
                
                await clientHelpers.updateClient(newClient);
                
                navigate('/submission');
            }
        } catch (error) {
            setError('Failed To Submit Form');
        }
    }

    return (
        <DashboardPageContainer>
            <PageTitle title="Complete Registration Form" />
            {
                error && <p className='text-danger'>{error}</p>
            }
            <form className="max-w-screen grid grid-cols-3 gap-2">
                <TextInput 
                    id='name'
                    label='name'
                    placeholder='Name...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.name?.message}
                    dataCyId="name"
                />

                <TextInput 
                    id='password'
                    label='password'
                    placeholder='Password...'
                    register={register}
                    containerStyles='my-0 p-0'
                    password
                    error={errors.password?.message}
                    dataCyId="password"
                />

                <TextInput 
                    id='email'
                    label='email'
                    placeholder='Email...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.email?.message}
                    dataCyId="email"
                />

                <TextInput 
                    id='address'
                    label='address'
                    placeholder='Address...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.address?.message}
                    dataCyId="address"
                />

                <TextInput 
                    id='city'
                    label='city'
                    placeholder='City...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.city?.message}
                    dataCyId="city"
                />

                <TextInput 
                    id='state'
                    label='state'
                    placeholder='State...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.state?.message}
                    dataCyId="state"
                />

                <TextInput 
                    id='zip'
                    label='zip'
                    placeholder='Postal Code...'
                    register={register}
                    error={errors.zip?.message}
                    dataCyId="zip"
                />

                <TextInput 
                    id='birthdate'
                    label='Birthdate'
                    placeholder='MM/DD/YYYY'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.birthdate?.message}
                    dataCyId="birthdate"
                />

                <TextInput 
                    id='phone'
                    label='Phone'
                    placeholder='Phone...'
                    register={register}
                    containerStyles='my-0 p-0'
                    error={errors.birthdate?.message}
                    dataCyId="phone"
                />
            </form>
            <div className='max-w-screen flex justify-center align-center'>
                <Button cyId="submit-button" text='Submit' onClick={handleSubmit(submit)} styles='w-[300px] mt-12' />
            </div>
        </DashboardPageContainer>
    )
}

export default ClientInvitationForm;