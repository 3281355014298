import React from "react";
import { NotificationThemes } from "../../context/DashboardContext";

interface ToastProps {
  message: string;
  theme: NotificationThemes;
}

const Toast: React.FC<ToastProps> = ({ message, theme }) => {
  const themeClass =
    theme === NotificationThemes.SUCCESS
      ? "border-green-500 text-green-500 font-semibold"
      : theme === NotificationThemes.FAIL
      ? "border-red-500 text-red-500 font-semibold"
      : "border-gray-500 text-gray-500"; 

  return (
    <div
      className={`fixed top-5 right-7 z-50 p-4 border-2 rounded-md shadow-md bg-gray-100 min-w-[150px] max-w-[300px] text-center text-sm  ${themeClass}`}
    
    >
      <p>{message}</p>
    </div>
  );
};

export default Toast;

