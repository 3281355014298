import { FunctionComponent, useState } from "react";
import { memberTableActions } from "../../components/actionMenu/types";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import { InviteMemberModal } from "../../components/modals";
import PageTitle from "../../components/PageTitle";
import useMembers from "../../hooks/useMembers";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import useCompanies from "../../hooks/useCompanies";
import { useUser } from "../../hooks/useUser";
import Dropdown from "../../components/inputs/Dropdown"

const Members: FunctionComponent = () => {
    const { user: { userId } } = useUser();
    const { members, loading } = useMembers();
    const { companies } = useCompanies(userId);

    const [selectedCompany, setSelectedCompany] = useState<string>("Choose a Company");

    const headerList = ['checkbox', 'company', 'name', 'email', 'phone', 'city', 'state', 'joined', 'actions']

    //@ts-ignore
    const formattedMembers = members
    ?.map((member) => {
        return {
            userId: member.userId,
            name: member.userInfo.name,
            email: member.userInfo.email,
            phone: member.userInfo.phone,
            city: member.userInfo.city,
            state: member.userInfo.state,
            joined: member.metadata.createdAt?.split(',')[0],
            company: companies.find(({id}) => member.userInfo.companyId === id)?.name || "ClientVitals"
        }
    });

    const filteredMembers =
    selectedCompany && (selectedCompany !=="Choose a Company")
    
    //@ts-ignore
        ? formattedMembers.filter((member) => member.company === selectedCompany) 
        : formattedMembers;   

    return (
        <DashboardPageContainer>
            <PageTitle title="Members" />
            <div className="flex justify-end items-center space-x-4 mb-4">
                <div className="w-64"> 
                    <Dropdown
                        id="company"
                        currentValue={selectedCompany}
                        onChange={setSelectedCompany} // Update state when a company is selected
                        defaultText="Choose a Company"
                        options={[
                            ...companies.map((company: any) => ({
                                value: company.userInfo.name,
                                label: company.userInfo.name,
                            })),
                        ]}
                    />
                </div>
                <InviteMemberModal />
            </div>
            {
                loading ? <Loading />
                        : <Table 
                            columnHeaders={headerList}
                            records={filteredMembers}
                            tableActions={memberTableActions}
                            linkBase="/dashboard/members"
                        />
            }
        </DashboardPageContainer>
    )
}

export default Members;