import { FunctionComponent } from "react";
import WideTile from "../tiles/WideTile";
import ReportCard from "../differentialReport/ReportCard";
import { CreditUnions } from "../../utils/SoftPullAPI";
import { RiskScale } from "../../constants/enums";
import SlimTile from "../tiles/SlimTile";
import Button from "../Button";
import Collapse from "../Collapse";
import ReferClientModal from "../modals/ReferClientModal";

interface ICreditReportProps {
    application: Record<any, any>
    reportASummary: any
    reportBSummary: any
    reportCSummary: any
    pastDue: number | string
    riskReport: Record<any, any>
    scoreCardReports: Record<any, any>
    creditReportType: CreditUnions
    user: Record<any, any>
    client: Record<any, any>
}

const CreditReport: FunctionComponent<ICreditReportProps> = ({
    application,
    reportASummary,
    reportBSummary,
    reportCSummary,
    pastDue,
    riskReport,
    scoreCardReports,
    creditReportType,
    user,
    client
}) => (
    <WideTile>
        <div className="flex flex-col justify-between">
            <div className="w-[100%] flex flex-wrap align-start justify-between">
                <ReportCard>
                    <h4 className="text-lg font-medium mb-6">Report Generation</h4>
                    
                    <div className="flex justify-between mb-2">
                        <p className="font-semibold">Date & Time:</p>
                        <p>{application.metadata.applicationSubmit}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p className="font-semibold">Pull Type:</p>
                        <p>{creditReportType === CreditUnions.TRI_MERGE ? 'Tri-Merge' : 'Single'}</p>
                    </div>
                    <div className="flex justify-between mb-2">
                        <p className="font-semibold">Software User:</p>
                        <p>{user.userInfo.name}</p>
                    </div>
                </ReportCard>

                <ReportCard>
                    <h4 className="text-lg font-medium mb-6">Credit Score</h4>
                    
                    <div className="mb-2">
                        <p>{application.applicationFormData.name} — ***-**-{application.applicationFormData.ssn.replaceAll('-','').substr(5)}</p>
                    </div>
                    
                    <div className="flex justify-between mb-2">
                        <p className="font-semibold">Score Threshold:</p>
                        {/* @ts-ignore */}
                        <p>{user?.metadata?.thresHold || '680 (ClientVitals Default)'}</p>
                    </div>

                    <p className="font-semibold">Actual Score:</p>
                    <div className="flex justify-between mb-2">    
                        <div>
                            <p className={`${Number(reportASummary?.scores[0]?.score) < (user?.metadata?.thresHold || 680) ? 'text-danger' : isNaN(reportASummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">Experian:</span> {reportASummary?.scores[0]?.score || 'No Hit'}</p>
                            <p className="text-primary hover:underline"><a href={scoreCardReports[0].reportLink} target="_blank">View Report</a></p>
                        </div>
                        
                        <div>
                            <p className={`${Number(reportBSummary?.scores[0]?.score) < (user?.metadata?.thresHold || 680) ? 'text-danger' : isNaN(reportBSummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">TransUnion:</span> {reportBSummary?.scores[0]?.score || 'No Hit'}</p>
                            <p className="text-primary hover:underline"><a href={scoreCardReports[1].reportLink} target="_blank">View Report</a></p>
                        </div>
                        
                        <div>
                            <p className={`${Number(reportCSummary?.scores[0]?.score) < (user?.metadata?.thresHold || 680) ? 'text-danger' : isNaN(reportCSummary?.scores[0]?.score) ? 'text-warning' : 'text-success'}`}><span className="font-semibold">Equifax:</span> {reportCSummary?.scores[0]?.score || 'No Hit'}</p>
                            <p className="text-primary hover:underline"><a href={scoreCardReports[2].reportLink} target="_blank">View Report</a></p>
                        </div>
                        
                    </div>
                </ReportCard>

                <ReportCard>
                    <h4 className="text-lg font-medium mb-2">Hardship Tracker</h4>

                    <div className="flex justify-between">

                        <div className="grid grid-cols-5 gap-2">
                            <div/>
                            <p className="font-semibold">Late Payments</p>
                            <p className="font-semibold">Collections</p>
                            <p className="font-semibold">Judgments</p>
                            <p className="font-semibold">Utilization</p>

                            <p className="font-semibold mb-3">Experian:</p>
                            <p>{(Number(reportASummary?.thirtyDays) + Number(reportASummary?.sixtyDays) + Number(reportASummary?.ninetyDays))}</p>
                            <p>{reportASummary?.collectionItems || 'Unknown'}</p>
                            <p>{reportASummary?.judgements || '0'}</p>
                            <p>{100 - reportASummary?.availableCredit || 'Unknown '}%</p>

                            <p className="font-semibold mb-3">TransUnion:</p>
                            <p>{(Number(reportBSummary?.thirtyDays) + Number(reportBSummary?.sixtyDays) + Number(reportBSummary?.ninetyDays))}</p>
                            <p>{reportBSummary?.collectionItems || 'Unknown'}</p>
                            <p>{reportBSummary?.judgements || '0'}</p>
                            <p>{100 - reportBSummary?.availableCredit || 'Unknown '}%</p>
                
                            <p className="font-semibold mb-3">Equifax:</p>
                            <p>{(Number(reportCSummary?.thirtyDays) + Number(reportCSummary?.sixtyDays) + Number(reportCSummary?.ninetyDays))}</p>
                            <p>{reportCSummary?.collectionItems || 'Unknown'}</p>
                            <p>{reportCSummary?.judgements || '0'}</p>
                            <p>{100 - reportCSummary?.availableCredit || 'Unknown '}%</p>
                    
                            <p className="font-semibold">Total: </p>
                            <p className="font-semibold">{pastDue}</p>
                            <p className="font-semibold">{(Number(reportASummary?.collectionItems) || 0) + (Number(reportBSummary?.collectionItems) || 0) + (Number(reportCSummary?.collectionItems) || 0)}</p>
                            <p className="font-semibold">{(Number(reportASummary?.judgements) || 0) + (Number(reportBSummary?.judgements) || 0) + (Number(reportCSummary?.judgements) || 0)}</p>
                            <p>N/A</p>
                        </div>
                    </div>
                </ReportCard>

                <ReportCard>
                    <h4 className="text-md font-medium mb-2">Tri-Merge Differentials</h4>
                    
                    <h5 className="font-semibold mb-4">Report Summary</h5>

                    <div className="flex justify-between">
                        <div className="pt-7">
                            <p className="font-semibold mb-3">TransUnion:</p>
                            <p className="font-semibold mb-3">Experian:</p>
                            <p className="font-semibold mb-3">Equifax:</p>
                        </div>

                        <div>
                            <div className="flex justify-between">
                                <p className="font-semibold mr-8">Amount Past Due</p>
                                <p className="font-semibold">90 Days Past Due</p>
                            </div>

                            <div className="flex justify-between mt-2 mb-3">
                                <p className="mr-8">{reportBSummary?.amountPastDue || 'Unknown'}</p>
                                <p >{reportBSummary?.ninetyDays || 'Uknown'}</p>
                            </div>

                            <div className="flex justify-between mb-3">
                                <p className="mr-8">{reportASummary?.amountPastDue || 'Unknown'}</p>
                                <p>{reportASummary?.ninetyDays || 'Unknown'}</p>
                            </div>

                            <div className="flex justify-between mb-3">
                                <p className="mr-8">{reportCSummary?.amountPastDue || 'Unknown'}</p>
                                <p>{reportCSummary?.ninetyDays || 'Unknown'}</p>
                            </div>
                        </div>
                    </div>
                </ReportCard>

                <ReportCard>
                    <h4 className="text-lg font-medium mb-6">Service Recommendations</h4>

                    <div className="w-full flex flex-col justify-center align-center">
                        {/* <CreditReportFunnel /> */}
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Payment History Severity:</p>
                            <p className={`${
                                riskReport?.pastDueRisk?.status === RiskScale[2] ? 'text-danger' : 
                                riskReport?.pastDueRisk?.status === RiskScale[1] ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.pastDueRisk?.status ? riskReport?.pastDueRisk?.status : ''}
                            </p>
                        </div>
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Utilization Severity:</p>
                            <p className={`${
                                riskReport?.utilizationRisk?.status === RiskScale[2] ? 'text-danger' : 
                                riskReport?.utilizationRisk?.status === RiskScale[1] ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.utilizationRisk?.status ? riskReport?.utilizationRisk?.status : ''}
                            </p>
                        </div>
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Credit Mix Severity:</p>
                            <p className={`${
                                riskReport?.creditMixRisk?.status === RiskScale[2] ? 'text-danger' : 
                                riskReport?.creditMixRisk?.status === RiskScale[1] ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.creditMixRisk?.status ? riskReport?.creditMixRisk?.status : ''}
                            </p>
                        </div>
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Inquiries Severity:</p>
                            <p className={`${
                                riskReport?.inqueryRisk?.status === RiskScale[2] ? 'text-danger' : 
                                riskReport?.inqueryRisk?.status === RiskScale[1] ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.inqueryRisk?.status ? riskReport?.inqueryRisk?.status : ''}
                            </p>
                        </div>
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Score Severity:</p>
                            <p className={`${
                                riskReport?.scoreRisk?.status === RiskScale[2] ? 'text-danger' : 
                                riskReport?.scoreRisk?.status === RiskScale[1] ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.scoreRisk?.status ? riskReport?.scoreRisk?.status : ''}
                            </p>
                        </div>
                        <div className="flex justify-between mb-2 w-full text-lg">
                            <p className="font-semibold">Overall Severity:</p>
                            <p className={`${
                                riskReport?.overallRisk === RiskScale.SEVERE ? 'text-danger' : 
                                riskReport?.overallRisk === RiskScale.MILD ? 'text-yellow-400' :
                                                                            'text-success'
                            } font-semibold`} >
                                {riskReport?.overallRisk ? RiskScale[riskReport?.overallRisk] : ''}
                            </p>
                        </div>
                    </div>

                    {
                        riskReport?.overallRisk && (
                            <SlimTile styles="mt-12 shadow-md">
                                <p className="font-semibold text-lg">Advised Actions</p>

                                <div className="flex flex-col w-full items-center">
                                    <p className="my-8 text-lg">
                                        {
                                        riskReport?.overallRisk === RiskScale.SEVERE ? 'Client has Intake & Assessment Call with ClientVitals' :
                                        riskReport?.overallRisk === RiskScale.MILD ? 'Client has Discovery Call with ClientVitals'
                                            : 'No Recommendations for This Client'
                                        } 
                                    </p>

                                    <ReferClientModal existingClient={client} startingNote={
                                        riskReport?.overallRisk === RiskScale.SEVERE ? 'Client has Intake & Assessment Call with ClientVitals' :
                                        riskReport?.overallRisk === RiskScale.MILD ? 'Client has Discovery Call with ClientVitals'
                                            : 'No Recommendations for This Client'
                                    } />
                                </div>
                            </SlimTile>
                        )
                    }
                </ReportCard>

                <ReportCard>
                    <h4 className="text-lg text-yellow-600 font-medium mb-2">Alerts & Notices</h4>
                    
                    <ul>
                        <Collapse title="Experian Alerts">
                        {
                            reportASummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                        }
                        </Collapse>
                        <Collapse title="TransUnion Alerts">
                        {
                            reportBSummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                        }
                        </Collapse>
                        <Collapse title="Equifax Alerts">
                        {
                            reportCSummary?.warnings?.map((warning: string) => <li className="mb-2">{warning}</li>)
                        }
                        </Collapse>
                    </ul>
                </ReportCard>
            </div>
        </div>
    </WideTile>
);

export default CreditReport;