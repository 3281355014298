import { FunctionComponent, useState } from "react";
import { ReactComponent as SupportIcon } from '../../assets/support-icon.svg';
import { TextInput } from "../inputs";
import { useForm } from "react-hook-form";
import Button, { BUTTON_THEMES } from "../Button";
import { useUser } from "../../hooks/useUser";

const Support: FunctionComponent = () => {
    const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;
    const [open, setOpen] = useState<boolean>(false);
    const [screenshot, setScreenshot] = useState<File | null>(null);

    const { user } = useUser();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            subject: '',
            page: '',
            action: '',
            details: '',
        }
    });

    const toggleOpen = () => setOpen(!open);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setScreenshot(event.target.files[0]);
        }
    };

    const submit = async (values: Record<any, any>) => {
        try {
            const formData = new FormData();
            formData.append("subject", values.subject);
            formData.append("page", values.page);
            formData.append("action", values.action);
            formData.append("details", values.details);
            formData.append("submittedBy", user.userId || 'N/A')
            
            if (screenshot) {
                formData.append("screenshot", screenshot);
            }
    
            const response = await fetch(`${BASE_URL}/tickets/add`, {
                method: 'POST',
                body: formData,
            });
    
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.log({ error });
        }
    };

    return (
        <>
        <div className="fixed bg-white rounded-full bottom-3 right-7 z-40 cursor-pointer" onClick={toggleOpen}>
            <SupportIcon height={32} width={32} />
        </div>
        {
            open && (
                <div className="flex flex-col justify-between border border-2 border-primary h-[450px] w-[300px] fixed bottom-14 right-6 z-40 bg-white rounded shadow-lg pb-6">
                    <h4 className="bg-primary h-12 text-white text-center pt-3">
                        Your Feedback Matters
                    </h4>

                    <form className="grid grid-cols-2 gap-1 px-4">
                            <TextInput
                                id="subject"
                                label="Subject"
                                placeholder="Subject"
                                register={register}
                            />

                            <TextInput
                                id="page"
                                label="Page"
                                placeholder="Page"
                                register={register}
                            />

                            <div className="col-span-2">
                                <TextInput
                                    id="action"
                                    label="Action"
                                    placeholder="Action taken..."
                                    register={register}
                                />

                                <TextInput
                                    id="details"
                                    label="Details"
                                    placeholder="Extra Details"
                                    register={register}
                                />
                            </div>

                            {/* File input for screenshot */}
                            <div className="col-span-2 mt-2">
                                <label htmlFor="screenshot" className="block text-sm font-medium text-gray-700">
                                    Screenshot
                                </label>
                                <input
                                    type="file"
                                    id="screenshot"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                                />
                            </div>
                    </form>
                    
                    <div className="flex justify-between items-end px-4">
                            <Button 
                                text="Cancel"
                                theme={BUTTON_THEMES.INVERSE}
                                onClick={toggleOpen}
                            />

                            <Button
                                text="Submit"
                                onClick={handleSubmit(submit)}
                            />
                    </div>
                </div>
            )
        }
        </>
    );
}

export default Support;

interface ITicketData {
    subject: string;
    page: string;
    action: string;
    details: string;
    screenshot: File | null;
}

interface ITicketMetadata {
    createdAt: Date;
    updatedAt: Date;
}

interface ISupportTicket {
    ticketId: string;
    submittedBy: string;
    data: ITicketData
    metadata: ITicketMetadata
}