import { FunctionComponent, ChangeEvent } from "react";
import { TextInput, Checkbox } from "../inputs";
import { UseFormRegister } from "react-hook-form";
import RadioGroup from "../inputs/RadioGroup";
import { UserTypes } from "../../constants";

interface IRegisterSubscriberFormProps {
    register: UseFormRegister<any>
    terms: boolean
    accountType: string;
    hideAccountTypes?: boolean;
    setTerms: (event: ChangeEvent<HTMLInputElement>) => void
    errors?: any
}

const RegisterSubscriberForm: FunctionComponent<IRegisterSubscriberFormProps> = ({ register, setTerms, hideAccountTypes, terms, errors, accountType }) => {
    return (
        <>
            {
                !hideAccountTypes && (
                    <>
                        <p className="ml-3 text-gray-700 text-sm font-bold">Account Type:</p>
                        <RadioGroup 
                            register={register}
                            selectedOption={accountType}
                            name="accountType"
                            containerStyles="flex justify-around items-end flex-wrap mb-4"
                            options={[
                                { id: UserTypes.MEMBER, label: 'Personal'},
                                { id: UserTypes.COMPANY, label: 'Company'},
                            ]}
                        />
                    </>
                )
            }
            <div className="w-full flex flex-wrap justify-around">
            <TextInput
                label='Email'
                id='email'
                placeholder='Contact email...'
                containerStyles='w-[45%]'
                testId='register-company-email-input'
                register={register}
                error={errors.email?.message}
            />
    
    
            <TextInput
                label='Password'
                id='password'
                password
                placeholder='Enter Password...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-password-input'
                error={errors.password?.message}
            />
    
            <TextInput
                label='Name'
                id='name'
                placeholder='Company name...'
                register={register}
                key='company'
                containerStyles='w-[45%]'
                testId='register-company-company-input'
                error={errors.name?.message}
            />
    
            <TextInput
                label='Phone'
                id='phone'
                placeholder='Contact phone number...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-phone-input'
                error={errors.phone?.message}
            />
    
            <TextInput
                label='Address'
                id='address'
                placeholder='Street address...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-address-input'
                error={errors.address?.message}
            />
    
            <TextInput
                label='City'
                id='city'
                placeholder='City...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-city-input'
                error={errors.city?.message}
            />
    
            <TextInput
                label='State'
                id='state'
                placeholder='State...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-state-input'
                error={errors.state?.message}
            />
    
            <TextInput
                label='Zip'
                id='postal_code'
                placeholder='Postal code...'
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-zip-input'
                error={errors.zip?.message}
            />
    
            <TextInput
                label='Referred By (Optional)'
                id='referredBy'
                placeholder="Referrer's name..."
                register={register}
                containerStyles='w-[45%]'
                testId='register-company-zip-input'
            />
            </div>
    
            <Checkbox
                labelText='Terms & Conditions'
                checked={terms}
                onChange={setTerms}
                labelStyles='ml-3'
                containerStyles='my-4'
                terms
            />
    
            <div className="w-[100%]" />
        </>
    );
}

export default RegisterSubscriberForm;