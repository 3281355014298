import { FunctionComponent } from "react";
import { TextInput }  from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { useUser } from "../../../hooks/useUser";
import { sendgridHelpers } from "../../../utils/ClientVitalsAPI";
import Button from "../../Button";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import { UserTypes } from "../../../constants";

interface IInviteClientFormProps {
    onCancel: () => void;
} 

interface IInviteClientForm {
    name: string;
    email: string;
}

const InviteClientForm: FunctionComponent<IInviteClientFormProps> = ({ onCancel }) => {
    const { user } = useUser();
    const { updateNotification } = useDashboard();
    const { register, handleSubmit } = useForm<IInviteClientForm>({
        defaultValues: {
            email: '',
            name: ''
        }
    });

    const generateFormLink = () => {

    }

    const submit = async (values: { email: string, name: string }) => {
        const isMember = user.userType === UserTypes.MEMBER;
        let formLink;
    
        if (isMember) {
            //@ts-ignore
            formLink = `${process.env.REACT_APP_URL_BASE}/client-invitation?fi=${user.userInfo?.companyId}&fe=${user.userId}&name=${values.name.split(' ').join('-')}&email=${values.email}`
        } else {
            formLink = `${process.env.REACT_APP_URL_BASE}/client-invitation?fi=${user.userId}&name=${values.name.split(' ').join('-')}&email=${values.email}`
        }
     
        const payload = {
            email: values.email,
            name: values.name,
            company: user.userInfo?.name || '',
            formLink
        }

        try {
            const result = await sendgridHelpers.inviteClient(payload);

            if (result.status === 202) {
                updateNotification({
                    message: 'Client Invite Sent',
                    theme: NotificationThemes.SUCCESS
                });
                onCancel();
            }
        } catch (error) {
                updateNotification({
                    message: 'Client Invite Failed To Send',
                    theme: NotificationThemes.FAIL
                });
                onCancel();
        }
    }
    

    return (
        <div>
            <TextInput
                label="Name"
                id="name"
                placeholder="Enter client name..."
                register={register}
                dataCyId="name"
            />

            <TextInput
                label="Email"
                id="email"
                placeholder="Enter client email..."
                register={register}
                dataCyId="email"
            />

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </div>
    )
}

export default InviteClientForm;