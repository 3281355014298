import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import Managers from '../pages/manager/Managers';

const ManagerRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Managers />} />
    </Routes>
);

export default ManagerRoutes;