import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import { ICustomerData, IPaymentData } from '../context/StripeContext';
import { stripeHelpers } from '../utils/ClientVitalsAPI';

const useCustomer = () => {
    const { user, getCurrentUserData, setUser } = useUser();
    //@ts-ignore
    const { stripeId } = user.metadata;

    const [customer, setCustomer] = useState<ICustomerData | null>(null);
    const [paymentMethod, setPaymentMethod] = useState<IPaymentData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchCustomerInformation = async () => {
        if(!stripeId) return;
        setLoading(true);
        try {
            const result = await stripeHelpers.fetchCustomerAndPayment(stripeId);

            setCustomer(result.data.customer);
            
            if(result.data.paymentMethod) setPaymentMethod(result.data.paymentMethod?.data[0]);
        } catch(error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const updateToCustomer = async () => {
        //@ts-ignore
        if(!user || stripeId) return;
       
        setLoading(true)
        try {
            await stripeHelpers.addNewCustomer(user);
            const userData = await getCurrentUserData(user.userId);
            setUser(userData)
            
            await fetchCustomerInformation();

        } catch(error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(!stripeId && user && user.metadata) {
            updateToCustomer();
        }
    }, []);

    useEffect(() => {
        if(stripeId && (!customer && !paymentMethod)) fetchCustomerInformation();
    }, [stripeId])
    
    return { 
        loading,
        fetchCustomerInformation,
        customer,
        paymentMethod
    }
}

export default useCustomer;