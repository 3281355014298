import { FunctionComponent } from "react";

import _ from 'lodash';

interface ICheckboxProps {
    labelText: string;
    containerStyles?: string;
    labelStyles?: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    register?: Function;
    terms?: boolean;
}
                
const Checkbox: FunctionComponent<ICheckboxProps> = ({ register, labelText, containerStyles, labelStyles, checked, terms, onChange }) => (
    register ? (
        <div className={`${containerStyles} flex items-center justify-center cursor-pointer`}>
            <input type="checkbox" className="form-checkbox h-3 w-3 accent-primary cursor-pointer" checked={checked} value={labelText} {...register(_.camelCase(labelText?.replaceAll(' ', '')))} />
            <label className={`text-gray-900 font-medium ${labelStyles}`}>{labelText}</label>
        </div>
    ) : (
        <div className={`flex items-center justify-center cursor-pointer ${containerStyles}`}>
            <input type="checkbox" className="form-checkbox h-3 w-3 accent-primary cursor-pointer" checked={checked} value={labelText} onChange={onChange} />
                <label className={`text-gray-900 font-medium ${labelStyles}`}>{terms ? <a className="text-primary hover:underline" target="_blank" rel="noopener noreferrer" href={'https://www.dropbox.com/scl/fi/ueb58s7eli31z6jfry3ke/ToS.pdf?rlkey=nq3erxn19cj0x808l7fapfb7t&st=aaexcp2s&dl=0'}>{labelText}</a> : labelText}</label>
        </div>
    )
);

export default Checkbox;