import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Members, MemberId } from '../pages/members';

const MemberRoutes: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Members />} />
        <Route path=':id' element={<MemberId />} />
    </Routes>
);

export default MemberRoutes;