import { FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import Button, { BUTTON_THEMES } from '../components/Button';
import RadialGradient from '../components/RadialGradient';
import Badge from '../components/Badge';
import Logo from '../assets/logo-color.png';

const Landing: FunctionComponent = () => (
  <div className="max-w-[1150px] mx-auto overflow-hidden">
    {/* Header Section */}
    <header className="relative flex flex-col justify-between items-center lg:justify-around lg:flex-row lg:items-center border-b border-secondary-dark p-4 sm:p-8 lg:py-20">
    
    {/* Logo */}
    <div className="flex-shrink-0">
        <img 
        src={Logo} 
        alt="ClientVitals Logo" 
        className="w-[200px] sm:w-[250px] lg:w-[300px]"
        />
    </div>
  
    {/* Hero Text */}
    <div className="max-w-[400px] text-center sm:text-left lg:text-right flex flex-col lg:mt-0 lg:mr-8 py-8">
        <h3 className="uppercase font-semibold text-xl sm:text-2xl lg:text-3xl mb-4">
        Enhancing Experiences
        </h3>
        <p className="text-sm sm:text-base md:text-lg mb-8">
        The ClientVitals system allows for quicker and more accurate insights to assist in your team's ability to provide reliable, efficient services to your clients. Submit your application today to get your account approved sooner.
        </p>
        {/* Buttons */}
        <div className="flex flex-col sm:flex-row items-center lg:justify-end gap-4 lg:mr-0">
        <Link to="/register">
            <Button 
            text="Register" 
            theme={BUTTON_THEMES.PRIMARY}
            styles="min-w-[150px]"
            testId="register-button-landing" 
            />
        </Link>
        <Link to="/login">
            <Button 
            text="Login" 
            theme={BUTTON_THEMES.PRIMARY}
            styles="min-w-[150px]"
            testId="login-button-landing" 
            />
        </Link>
        </div>
    </div>
    </header>
    {/* Features Section */}
    <section className="flex flex-col justify-center items-center border-b border-secondary-dark relative py-8 px-4 lg:pt-20 lg:pb-24">
      <div className="max-w-[800px] mb-4">
        <h3 className="font-semibold text-xl sm:text-2xl lg:text-3xl mb-4">
          Welcome to the ClientVitals Solutions Softpull Dashboard
        </h3>
        <p className="text-sm sm:text-base md:text-lg mb-4">
          The ultimate tool for financial professionals seeking to streamline and enhance loan readiness for their clients.
        </p>
        <p className="text-sm sm:text-base md:text-lg mb-6">
          Our dashboard provides quick, secure access to comprehensive client liability profiles through a FICO 8 soft pull, allowing you to assess risks, manage debt, and optimize client lending profiles without impacting credit scores.
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base md:text-lg mb-4">
          <li>Quickly identify potential loan denial risks with real-time data.</li>
          <li>Offer pre-qualification tools by utilizing industry-leading soft pull technology.</li>
          <li>Deliver tailored recommendations to clients based on accurate, up-to-date information.</li>
          <li>Optimize client profiles for fast lending approvals.</li>
        </ul>
        <p className="text-sm sm:text-base md:text-lg">
          Unlock a new level of client engagement with ClientVitals Solutions Softpull Dashboard and enjoy greater confidence in the loan readiness process.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full mt-8 justify-items-center">
        {['Team Management', 
          'Client Management', 
          'Soft Pulls', 
          'Data Insights', 
          'Software Support', 
          'Fast Communication'
        ].map((text, idx) => (
          <Badge 
            key={idx} 
            text={text} 
            //className="hover:scale-105 hover:shadow-strong transition-transform duration-300"
          />
        ))}
      </div>
    </section>

    {/* Footer Section */}
    <footer className="flex flex-col sm:flex-row justify-around items-center relative py-8 lg:py-24 px-4 ">
      <div className="max-w-[350px] text-center sm:text-left mb-8 sm:mb-0">
        <h2 className="uppercase font-semibold text-xl sm:text-2xl mb-2">
          Get In Touch
        </h2>
        <h3 className="uppercase font-medium text-lg sm:text-xl mb-6">
          Apply to start enhancing productivity
        </h3>

        <div>
          <Link to="/register">
            <Button 
              text="Register Now" 
              styles="min-w-[150px] hover:bg-primary-dark hover:shadow-strong transition-all duration-300 mr-4" 
            />
          </Link>

          <Link to="/register-referral">
            <Button 
              text="Refer Clients" 
              styles="min-w-[150px] hover:bg-primary-dark hover:shadow-strong transition-all duration-300" 
            />
          </Link>
        </div>

      </div>
      <div className="text-center sm:text-left">
        <h5 className="text-xl font-semibold mb-3 border-b border-secondary-dark">
          Contact Us:
        </h5>
        <p>ClientVitals Solutions</p>
        <p>Shelburne, VT. 05482</p>
        <p>802-922-6268</p>
        <p>
          <a 
            href="mailto:clientvitals@clientvitals.com" 
            className="text-primary hover:underline"
          >
            clientvitals@clientvitals.com
          </a>
        </p>
      </div>
    </footer>
  </div>
);

export default Landing;
