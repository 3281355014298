import { FunctionComponent, useState } from "react";
import { clientTableActions } from "../../components/actionMenu/types";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import { InviteClientModal } from "../../components/modals";
import PageTitle from "../../components/PageTitle";
import useClients from "../../hooks/useClients";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import ReferClientModal from "../../components/modals/ReferClientModal";
import useMembers from "../../hooks/useMembers";
import { useUser } from "../../hooks/useUser";
import Dropdown from "../../components/inputs/Dropdown";
import { ACCESS_LEVEL } from "../../constants";
import useCompanies from "../../hooks/useCompanies";

const Clients: FunctionComponent = () => {
    const { user } = useUser();
    const { metadata } = user;
    const { accessLevel } = metadata;
    const { clients, loading } = useClients();
    const { members } = useMembers();
    
    const [selectedMember, setSelectedMember] = useState<string>('Choose A Member');
     
    const headerList = ['checkbox', 'name', 'email', 'phone', 'city', 'state', 'member', 'joined', 'actions']
    //@ts-ignore
    const formattedClients = clients?.filter((client) => !client.metadata.archived)
    ?.map((client) => {
        return {
            userId: client.userId,
            name: client.userInfo.name,
            email: client.userInfo.email,
            phone: client.userInfo.phone,
            city: client.userInfo.city,
            state: client.userInfo.state,
            member: members.find(({userId}) => userId === client.userInfo.memberId)?.userInfo?.name || "N/A",
            memberId: client.userInfo.memberId,
            joined: client.metadata.createdAt?.split(',')[0]
        }
    });
    console.log({clients, formattedClients})
    const MemberDropdown: FunctionComponent = () => (
        <div className="mr-4">
            <Dropdown
                id="member"
                defaultText="Choose A Member"
                currentValue={selectedMember}
                onChange={setSelectedMember}
                options={members?.map(({userId, userInfo}) => {
                    return { value: userId, label: userInfo?.name }
                })}
            />
        </div>
    )

    const CustomButtons: FunctionComponent = () => (
        <div className="flex justify-between items-center">
            {accessLevel <= ACCESS_LEVEL.CVA ? <MemberDropdown /> : <></>}
            <InviteClientModal />
            <ReferClientModal />
        </div>
    );

    return (
        <DashboardPageContainer>
            <PageTitle title="clients" />

            {
                loading ? <Loading />
                        : <Table
                            columnHeaders={headerList}
                            records={selectedMember === 'Choose A Member' ?  formattedClients : formattedClients.filter((record) => record.memberId === selectedMember)}
                            tableActions={clientTableActions}
                            customButton={<CustomButtons />}
                            linkBase="/dashboard/clients"
                        />
            }
        </DashboardPageContainer>
    );
}

export default Clients;