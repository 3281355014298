import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';
import { FunctionComponent } from "react";
import { Member } from '../../constants/members';

interface IMemberInfoTileProps {
    formToggle: () => void;
    member: Member;
    company?: string;
}

const MemberInfoTile: FunctionComponent<IMemberInfoTileProps> = ({ member, company, formToggle }) => (
    <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">Member Information</h4>
            <p className='mt-1'>
                <EditIcon
                    height={25}
                    width={25}
                    onClick={formToggle}
                    className="cursor-pointer"
                    data-testid='edit-member-icon'
                />
            </p>
        </div>

        <div className="text-md flex align-center">
            <div className="mr-[5%]">
                <p className="mb-3"><span className="font-medium">Name:</span> {member?.userInfo?.name}</p>
                <p className="mb-3"><span className="font-medium">Email:</span> {member?.userInfo?.email}</p>
                <p className="mb-3"><span className="font-medium">Phone:</span> {member?.userInfo?.phone}</p>
                <p className="mb-3"><span className="font-medium">DoB:</span> {member?.userInfo?.dob}</p>
            </div>

            <div className="mr-[5%]">
                <p className="mb-3"><span className="font-medium">Address:</span> {member?.userInfo?.address}</p>
                <p className="mb-3"><span className="font-medium">City:</span> {member?.userInfo?.city}</p>
                <p className="mb-3"><span className="font-medium">State:</span> {member?.userInfo?.state}</p>
                <p className="mb-3"><span className="font-medium">Postal Code:</span> {member?.userInfo?.zip}</p>
            </div>

            <div>
                <p className="mb-3"><span className="font-medium">Account Creation:</span> {member?.metadata?.createdAt}</p>
                <p className="mb-3"><span className="font-medium">Last Update:</span> {member?.metadata?.updatedAt}</p>
                <p className="mb-3"><span className="font-medium">Admin:</span> {String(member?.metadata?.isAdmin)}</p>
                {company && <p className="mb-3"><span className="font-medium">Invited By:</span> {company}</p>}
            </div>
        </div>
    </>
);

export default MemberInfoTile;