import{ useState, ReactNode, FunctionComponent } from 'react';

interface CollapseProps {
  title: string;
  children: ReactNode;
  styles?: string;
}

const Collapse: FunctionComponent<CollapseProps> = ({ title, children, styles }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => setIsOpen(!isOpen);

  return (
    <div className={`border rounded shadow-sm ${styles}`}>
      <button
        onClick={toggleCollapse}
        className="w-full flex justify-between items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium rounded-t focus:outline-none"
      >
        <span>{title}</span>
        <svg
          className={`w-5 h-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="px-4 py-3 bg-white border-t">
          {children}
        </div>
      )}
    </div>
  );
};

export default Collapse;
