import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import MemberInfoTile from "../../components/members/MemberInfoTile";
import EditMemberForm from "../../components/forms/EditMemberForm";
import PageTitle from "../../components/PageTitle";
import useMembers from "../../hooks/useMembers";
import WideTile from "../../components/tiles/WideTile";
import Loading from "../../components/Loading";
import useCompanies from "../../hooks/useCompanies";

const MemberId: FunctionComponent = () => {
    const [showForm, setShowForm] = useState<boolean>(false);
    
    const { getMemberById } = useMembers();
    const { pathname } = useLocation();
    const pathArray = pathname.split('/');
    const memberId = pathArray[pathArray.length - 1];
    const member = getMemberById(memberId);

    const { companies } = useCompanies(memberId);
    const company = companies?.find(({userId}) => userId === member?.userInfo?.companyId);
    const formToggle = () => setShowForm(!showForm)

    return (
        <DashboardPageContainer>
            <PageTitle title={`${member?.userInfo.name}`} />
            <WideTile>
            {
                ((!showForm && !member)) ? <Loading />
                    : (!showForm && member) ? <MemberInfoTile member={member} company={company?.name} formToggle={formToggle} />
                        : <EditMemberForm member={member} company={company?.name} toggleForm={formToggle} />
            }
            </WideTile>
        </DashboardPageContainer>
    )
}

export default MemberId;