import { useState, FunctionComponent } from "react";
import DashboardPageContainer from "../components/DashboardPageContainer";
import PageTitle from "../components/PageTitle";
import Button, { BUTTON_THEMES } from "../components/Button";
import Stepper from "../components/stepper";
import Papa from 'papaparse';
import UploadClientStepOne from "../components/upload/UploadClientStepOne";
import UploadClientStepTwo from "../components/upload/UploadClientStepTwo";
import { useUser } from "../hooks/useUser";
import { UserTypes, ACCESS_LEVEL } from "../constants";
import { sendgridHelpers } from "../utils/ClientVitalsAPI";
import UploadClientStepThree from "../components/upload/UploadClientStepThree";
import RadioGroup from "../components/inputs/RadioGroup";

const Upload: FunctionComponent = () => {
     const { user } = useUser();

     const [currentStep, setCurrentStep] = useState<number>(1);
     const [csvData, setCsvData] = useState<any[]>([]);
     const [inviteReport, setInviteReport] = useState<any[]>([]);
     const [uploadType, setUploadType] = useState<string>('client');

     const goNext = () => setCurrentStep(currentStep + 1);
     const goBack = () => setCurrentStep(currentStep - 1);

     const uploadAndInviteClient = async () => {
          const inviteObjs = csvData?.map((data: any) => {
               const isMember = user.userType === UserTypes.MEMBER;
               const name = data[0];
               const email = data[1];

               let formLink;
           
               if (isMember) {
                   //@ts-ignore
                   formLink = `${process.env.REACT_APP_URL_BASE}/client-invitation?fi=${user.userInfo?.companyId}&fe=${user.userId}&name=${name.split(' ').join('-')}&email=${email}`
               } else {
                   formLink = `${process.env.REACT_APP_URL_BASE}/client-invitation?fi=${user.userId}&name=${name.split(' ').join('-')}&email=${email}`
               }
               return { name, email, formLink }
          });

          try {
               const result = await sendgridHelpers.bulkInviteClient(inviteObjs);

               setInviteReport(result);
               goNext();
          } catch (error) {
               console.log(error)
          }
     }

     const uploadAndInviteMember = async () => {
          const inviteObjs = csvData?.map((data: any) => {
               const name = data[0];
               const email = data[1];
               const company = user?.metadata?.accessLevel === ACCESS_LEVEL.COMPANY ? user.userId : process.env.REACT_APP_SUB_ACCOUNT || "";
               const formLink = `${process.env.REACT_APP_URL_BASE}/member-invitation?fi=${user.userId}&name=${name.split(' ').join('-')}&email=${email}`;

               return { name, email, company, formLink }
          });

          try {
               const result = await sendgridHelpers.bulkInviteMember(inviteObjs);

               setInviteReport(result);
               goNext();
          } catch (error) {
               console.log(error)
          }
     }

     const handleFileUpload = (event: any) => {
          const file = event.target.files[0];
          if (file) {
              Papa.parse(file, {
                  skipEmptyLines: true,
                  complete: (result) => {
                      setCsvData(result.data);
                      goNext();
                  },
                  error: (error) => {
                      console.error("Error parsing CSV file:", error);
                  }
              });
          }
      };

     const goNextSwitch = () => {
          switch(currentStep) {
               case 1: 
                    return goNext;

               case 2: 
                    return uploadType === 'client' ? uploadAndInviteClient : uploadAndInviteMember;

               case 3: 
                    return;
          }
     }

      console.log(csvData)

     return (
          <DashboardPageContainer>
               <PageTitle title={`${uploadType === 'client' ? 'Client' : 'User'} Document Upload`} ActionButton={RadioGroup} ActionButtonProps={{
                    options: [{id: 'client', label: 'Upload Clients'}, {id: 'member', label: 'Upload Members'}],
                    name: 'uploadType',
                    selectedOption: uploadType,
                    onChange: setUploadType,
                    containerStyles: 'flex justify-between items-center flex-row',
                    labelTextStyles: 'font-semibold ml-2 mr-4'
               }} />

               <div className="w-full flex-col flex justify-between items-center my-6">
                    <div className="w-full flex justify-center items-center pr-12 my-6">
                         <Stepper currentStep={currentStep} totalSteps={3} />
                    </div>

                    {
                         currentStep === 1 ? <UploadClientStepOne handleFileUpload={handleFileUpload} />
                              : currentStep === 2 ? <UploadClientStepTwo csvData={csvData} />
                                   : <UploadClientStepThree report={inviteReport} />
                    }

                    <div className="w-full flex justify-between items-end">
                         <Button text="Back" disabled={currentStep === 1} theme={BUTTON_THEMES.INVERSE} onClick={goBack} />
                         <Button text="Next" disabled={currentStep === 3} onClick={goNextSwitch()} />
                    </div>
               </div>
          </DashboardPageContainer>
     )
};

export default Upload;