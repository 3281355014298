import { useState, useEffect } from 'react';
import { clientHelpers } from '../utils/ClientVitalsAPI';
import { Client } from '../constants/clients';
import { useUser } from './useUser';
import { UserTypes } from '../constants';

const useClients = () => {
    const { user } = useUser();
    const userType = user?.userType;
    const userId = user?.userId;

    const [clients, setClients] = useState<Client[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchClients = async () => {
        if (!loading) setLoading(true);

        const userTypeLower = userType.toLowerCase();
        console.log({userTypeLower})
        const fetchFunction = (userTypeLower === UserTypes.OWNER.toLowerCase() || userTypeLower === UserTypes.MANAGER.toLowerCase())  ? clientHelpers.getAllClients
            : userTypeLower === UserTypes.COMPANY.toLowerCase() ? clientHelpers.getCompanyClients
                : clientHelpers.getMemberClients
                console.log({fetchFunction})
        try {
            const fetchedClients = await fetchFunction(userId);
            console.log({fetchClients})
            setClients(fetchedClients)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getClientById = (id: string) => {
        return clients.find(({ userId }) => userId === id);
    }

    useEffect(() => {
        if(userId) fetchClients();
    }, []);
    
    return { 
        clients, 
        loading, 
        error, 
        fetchClients ,
        getClientById
    }
}

export default useClients;