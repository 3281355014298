import { FunctionComponent, useState, useEffect } from "react";
import { subscriptionHelpers } from "../utils/ClientVitalsAPI";
import { applicationTableActions } from "../components/actionMenu/types";
import DashboardPageContainer from "../components/DashboardPageContainer";
import PageTitle from "../components/PageTitle";
import Table from "../components/table";
import { InviteCompanyModal } from "../components/modals";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";
import Loading from "../components/Loading";


const AccountApplications: FunctionComponent = () => {
    const [applications, setApplications] = useState<any>(null);
    const { updateNotification } = useDashboard();

    const fetchapplications = async () => {
        try {
            const applications = await subscriptionHelpers.getSubscriberApplications();

            setApplications(applications);
        } catch (error) {
            setApplications([]);
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Fetch Companies'
            });
        }
    }

    const columnHeaders: string[] = ['checkbox', 'account', 'name', 'email', 'phone', 'status', 'city', 'state', 'actions']
    const formattedApplications: Record<string, any>[] = applications?.map((application: any) => {
        return {
            name: application.applicationFormData.name,
            email: application.applicationFormData.email,
            phone: application.applicationFormData.phone,
            status: application.metadata.status,
            city: application.applicationFormData.city,
            address: application.applicationFormData.address,
            zip: application.applicationFormData.zip,
            state: application.applicationFormData.state,
            id: application.applicationId,
            account: application.applicationType
        } 
    });

    useEffect(() => {
        fetchapplications();
    }, [])
    
    return (
        <DashboardPageContainer>
            <PageTitle title='Account Applications' />

            {
                applications === null ? <Loading />
                                        : <Table
                                                columnHeaders={columnHeaders}
                                                records={formattedApplications}
                                                tableActions={applicationTableActions}
                                                customButton={<InviteCompanyModal />}
                                            />
            }
        </DashboardPageContainer>
    )
}

export default AccountApplications;