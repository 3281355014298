import axios from "axios";
import { Company } from "../../constants/companies";
import { Member } from "../../constants/members";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const getCompanies = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/companies/all`);
        //@ts-ignore
        const companies = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))
                
        return companies
    } catch (error) {
        throw error;
    }   
}

const updateCompany = async (company: Company | Member) => {
    if(!company) return;

    try {
        const result = await axios.post(`${BASE_URL}/companies/update`, company);

        return result;
    } catch(error) {
        throw error;
    }
}

const archiveCompany = async (companyId: string) => {
    try {
        const link = `${BASE_URL}/companies/archive`;
       
        const result = await axios.post(link, {companyId});
       
        return result
    } catch (error) {
        throw error;
    }
}

export default {
    getCompanies,
    updateCompany,
    archiveCompany
}