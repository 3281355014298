import ReactDOM from 'react-dom/client';
import { initializeApplication } from './AppInit';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  initializeApplication()
);