import { FunctionComponent } from "react";
import { TextInput } from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { useUser } from "../../../hooks/useUser";
import { sendgridHelpers } from "../../../utils/ClientVitalsAPI";
import Button from "../../Button";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";

interface IReferExistingClientFormProps {
    client: Record<any, any>;
    startingNote?: string;
    onCancel: () => void;
} 

interface IReferExistingClientForm {
    notes: string;
}

const ReferExistingClientForm: FunctionComponent<IReferExistingClientFormProps> = ({ client, startingNote, onCancel }) => {
    const { user } = useUser();
    const { updateNotification } = useDashboard();
    const { register, handleSubmit } = useForm<IReferExistingClientForm>({
        defaultValues: {
            notes: startingNote || ''
        }
    });
    
    const submit = async (values: { notes: string }) => {
        const payload = {
            client,
            notes: values.notes,
            referrer: user.userInfo?.name || ''
        }

        try {
            const result = await sendgridHelpers.referExistingClient(payload);

            if (result.status === 202) {
                updateNotification({
                    message: 'Client Referral Sent',
                    theme: NotificationThemes.SUCCESS
                });
                onCancel();
            }
        } catch (error) {
                updateNotification({
                    message: 'Client Referral Failed To Send',
                    theme: NotificationThemes.FAIL
                });
                onCancel();
        }
    }

    return (
        <div>
            <p className="mt-2 mb-6">Refer <span className="font-semibold">{client.userInfo.name}</span> to ClientVitals Consulting Services?</p>

            <div>
                <TextInput
                    label="Notes"
                    id="notes"
                    placeholder="Enter any notes on the client..."
                    register={register}
                />
            </div>

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </div>
    )
}

export default ReferExistingClientForm;