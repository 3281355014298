import { useEffect, useState } from "react";
import { clientHelpers, creditHelpers } from "../utils/ClientVitalsAPI";


const useCreditApplications = (clientId?: string) => {
    const [selectedClientId, setSelectedClientId] = useState<string>(clientId || '');
    const [applications, setApplications] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);
    
    const fetchApplications = async () => {
        if (!loading) setLoading(true);
        
        try {
            const client = await clientHelpers.fetchClientById(selectedClientId || '');
            if (!client) return;
            const fetchedApplications: Record<string, any>[] = await creditHelpers.fetchAppsByClient(client.userInfo.email || '');

            setApplications(fetchedApplications);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getApplication = (appId: string) => applications.filter(app => app.applicationId === appId)[0];
    
    useEffect(() => {
        if (selectedClientId) fetchApplications();
    }, [selectedClientId]);

    return { applications, loading, error, selectedClientId, fetchApplications, setSelectedClientId, getApplication }
}

export default useCreditApplications;