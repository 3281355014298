import { FunctionComponent, MouseEvent } from "react";

export enum BUTTON_THEMES {
    PRIMARY = 'PRIMARY',
    INVERSE = 'INVERSE',
    DISABLED = 'DISABLED'
}

interface IButtonProps {
    text: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    theme?: 'PRIMARY' | 'INVERSE' | 'DISABLED';
    type?: 'submit';
    styles?: string;
    testId?: string;
    cyId?: string;
    disabled?: boolean;
}

const Button: FunctionComponent<IButtonProps> = ({ 
    text, 
    onClick, 
    styles, 
    theme, 
    type, 
    testId, 
    disabled = false, 
    cyId
}) => {
    const baseStyles = 'py-0 px-4 text-sm text-center font-medium rounded-md transition duration-300 w-[120px] h-[35px] uppercase';

    let themeStyles='';

    switch (theme) {
        case BUTTON_THEMES.PRIMARY:
            themeStyles = 'bg-primary text-white hover:bg-primary-dark';
            break;
        
        case BUTTON_THEMES.INVERSE:
            themeStyles = 'bg-white text-primary border border-primary hover:bg-primary-light';
            break;
        
        case BUTTON_THEMES.DISABLED:
            themeStyles = 'bg-gray-300 text-gray-600 cursor-not-allowed';
            break;    
        
        default:
            themeStyles = 'bg-primary text-white hover:bg-primary-dark';
            break;
    }

    return (
        <button
            className={`${baseStyles} ${styles} ${themeStyles}`}
            onClick={onClick}
            data-testid={testId}
            type={type}
            disabled={disabled}
            data-cy={cyId}
        >
            {text}
        </button>
    );
}
export default Button;