import axios from "axios";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const getCurrentUserData = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/users/${id}`);
        
        return result?.data?.data?.Items[0]
    } catch (error) {
        throw error;
    }
}

const getUserById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/users/${id}`);
   
        return result?.data?.data?.Items[0]
    } catch (error) {
        throw error;
    }
}

export default {
    getCurrentUserData,
    getUserById
}   