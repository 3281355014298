import * as yup from "yup";

const AddManagerSchema = yup.object().shape({
    name: yup
        .string()
        .required('Please Enter Company Name'),
    phone: yup
        .string()
        .min(10, 'Please Enter 10 Digit Phone Number')
        .required('Please Enter Postal Code'),
    email: yup
        .string()
        .email("Please enter a valid email!")
        .required("Email is required!"),
    password: yup
        .string()
        .min(8, "Password must be longer than 8 characters")
        .required("Password is required"),
    i: yup
        .string()
        .required(),
});

export default AddManagerSchema;