import { FunctionComponent, useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { ACCESS_LEVEL } from "../../constants";
import SlimTile from "../tiles/SlimTile";
import { stripeHelpers } from "../../utils/ClientVitalsAPI";
import { TextInput } from "../inputs";
import Button from "../Button";
import Dropdown from "../inputs/Dropdown";


const OneTimePayment: FunctionComponent = () => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<string>('');
    const [paymentMethod, setPaymentMethod] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(true);

    const { user } = useUser();

    const getCustomers = async () => {
        setLoading(true)
        try {
            const customerList = await stripeHelpers.fetchCustomerList();
       
            if(Array.isArray(customerList.data)) {
                setCustomers(customerList.data);
            }
        } catch (error) {
            console.log({error});
        } finally {
            setLoading(false)
        }
    }

    const selectCustomer = (selectedId: string) => {
        const currentCustomer = customers.find(cust => cust.id === selectedId);
   
        setSelectedCustomer(selectedId);
        setPaymentMethod(currentCustomer?.invoice_settings?.default_payment_method || '')
    }

    const submit = async () => {
        try {
            console.log({
                selectedCustomer,
                paymentMethod,
                amount: amount*100
            });

            const result = await stripeHelpers.oneTimeCharge(selectedCustomer, paymentMethod, amount*100) // x100 to convert formatting for stripe

            console.log(result);
        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCustomers();
    }, []);
    
    if(!user || user.metadata.accessLevel > ACCESS_LEVEL.CVA) {
        return <p>Access Restricted</p>
    }
   
    return (
        <SlimTile>
            <h5 className="uppercase font-medium text-md text-[#AEA8A8] my-3">
                One Time Charge:
            </h5>

            <div className="flex flex-col items-end">
                <form className="py-4 w-full">
                    <div className="mb-4">
                        <Dropdown
                            id="customerId"
                            currentValue={selectedCustomer}
                            onChange={selectCustomer}
                            defaultText="Choose A Customer"
                            options={customers?.map(({id, name}) => {
                                return { value: id, label: name }
                            })}
                        />
                    </div>

                    <TextInput 
                        id="amount"
                        label="Amount"
                        placeholder="10.00"
                        onChange={(value) => setAmount(Number(value))}
                    />
                </form>

                <Button text="Charge" onClick={submit} />
            </div>
        </SlimTile>
    );
}

export default OneTimePayment;