import axios from "axios";
import { Member, IMemberInvitationForm } from "../../constants/members";
import { ACCESS_LEVEL, UserTypes } from "../../constants";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const formatMember: (data: IMemberInvitationForm, companyId: string) => Member = (data, companyId) => {
    return {
        userId: '',
        userType: UserTypes.MEMBER,
        userInfo: {
            name: data.name,
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            country: '',
            email: data.email,
            companyId: companyId,
            dob: data.birthdate
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            isAdmin: false,
            browserUsed: 'chrome', 
            reApply: false,
            accessLevel: ACCESS_LEVEL.MEMBER,
            invitedBy: companyId
        }
    }
}

const getAllMembers = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/members/all`);
        //@ts-ignore
        const members = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))
        
        return members;
    } catch (error) {
        throw error;
    }
}

const addMember = async (member: Member) => {
    try {
        const result = await axios.post(`${BASE_URL}/members/add`, member);

        return result
    } catch (error) {
        throw error;
    }
}

const getCompanyMembers = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/members/company/${id}`);
        //@ts-ignore
        const members = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))
                
        return members;
    } catch (error) {
        throw error;
    }
}

const updateMember = async (memberData: Member) => {
    try {
        const link = `${BASE_URL}/members/update`;
       
        const result = await axios.put(link, memberData);

        return result
    } catch (error) {
        throw error;
    }
}

const fetchMemberById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/members/single/${id}`);

        return result?.data?.result?.Items[0]
    } catch (error) {
        throw error;
    }
}

const archiveMember = async (memberId: string) => {
    try {
        const link = `${BASE_URL}/members/archive`;
       
        const result = await axios.put(link, {memberId});

        return result
    } catch (error) {
        throw error;
    }
}

export default {
    addMember,
    getAllMembers,
    formatMember,
    getCompanyMembers,
    updateMember,
    fetchMemberById,
    archiveMember
}