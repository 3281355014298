import axios from "axios";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

// Support Ticket Helpers
const addSupportTicket = async (data: Record<any, any>) => {
    try {
        const result = await axios.post(`${BASE_URL}/tickets/add`, data);

        return result
    } catch (error) {
        throw error;
    }
}

const getSupportTickets = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/tickets/all`);
        console.log({result});
        return result?.data?.result
    } catch (error) {
        throw error;
    }
}

export default {
    addSupportTicket,
    getSupportTickets
}