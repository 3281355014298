import { FunctionComponent, useState, useEffect } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import useClients from "../../hooks/useClients";
import { useParams } from "react-router-dom";
import { SendApplicationInviteLinkModal } from "../../components/modals";
import { creditHelpers } from "../../utils/ClientVitalsAPI";
import Table from "../../components/table";
import Loading from "../../components/Loading";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import Dropdown from "../../components/inputs/Dropdown";


const ClientIdApplications: FunctionComponent = () => {
   const [clientApps, setClientApps] = useState<any[] | undefined>();
   const { getClientById, clients } = useClients();
   const { updateNotification } = useDashboard();
   const { id } = useParams();   

   const startId = (id && id !== ':id' && id !== 'undefined') ? id : clients[0]?.userId;

   const [clientId, setClientId] = useState<string>(startId);

   const client = getClientById(clientId || startId);

   useEffect(() => {
      const fetch = async () => {
         try {
            if (!client) return;
            const result = await creditHelpers.fetchAppsByClient(client?.userInfo.email);

            setClientApps(result);
         } catch (error) {
            updateNotification({
               theme: NotificationThemes.FAIL,
               message: 'Failed To Fetch Applications'
            })
         }
      }
      if (client) fetch()
      }, [client]);
   
   if (!clients || !clientApps) return <Loading />

   const formattedApps = clientApps?.map((app) => {
      return {
            applicationId: app.applicationId,
            name: app.applicationFormData.name,
            email: app.applicationFormData.email,
            phone: app.applicationFormData.phone
        }
    });

   return (
      <DashboardPageContainer>
         <PageTitle 
            title={client ? `${client.userInfo.name}'s Credit Applications` : 'Select Client to View Applications'} 
            ActionButton={SendApplicationInviteLinkModal}
            ActionButtonProps={{
               clientId: clientId
            }}
         />

         <div className="w-[100%] max-w-screen flex justify-end items-end">
            <form>
               <Dropdown
                  id="clients"
                  currentValue={clientId}
                  onChange={setClientId}
                  defaultText="Choose A Client"
                  //@ts-ignore
                  options={clients?.filter((client) => !client.metadata.archived).map(({userId, userInfo}) => {
                     return { value: userId, label: userInfo.name }
                  })}
               />
            </form>
         </div>

         <div>
           <Table
               columnHeaders={['checkbox', 'name', 'email', 'phone']}
               records={formattedApps}
               tableActions={[]}
               linkBase={`/dashboard/clients/${clientId || startId}/applications`}
            />
         </div>
      </DashboardPageContainer>
   )
}

export default ClientIdApplications;