import * as yup from "yup";

const MemberInvitationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Please Enter Company Name'),
    address: yup
        .string()
        .required('Please Enter Address'),
    city: yup
        .string()
        .required('Please Enter City'),
    state: yup
        .string()
        .required('Please Enter State'),
    zip: yup
        .string()
        .required('Please Enter Postal Code'),
    phone: yup
        .string()
        .min(10, 'Please Enter 10 Digit Phone Number')
        .required('Please Enter Postal Code'),
    email: yup
        .string()
        .email("Please enter a valid email!")
        .required("Email is required!"),
    password: yup
        .string()
        .min(8, "Password must be longer than 8 characters")
        .required("Password is required"),
    birthdate: yup
        .string()
        .required(),
    companyId: yup.string().required('Contact Support For Company ID'),
    code: yup.string(),
});

export default MemberInvitationSchema;