//@ts-nocheck
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import Button, { BUTTON_THEMES } from "../Button";
import { Checkbox, TextInput } from "../inputs";
import { Member } from "../../constants/members";
import { yupResolver } from "@hookform/resolvers/yup";
import EditMemberSchema from "./ValidationScehma/EditMemberSchema";
import { memberHelpers } from "../../utils/ClientVitalsAPI";
import { ACCESS_LEVEL } from "../../constants";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";

interface IEditMemberFormProps {
    toggleForm: () => void;
    member?: Member;
    company?: string;
}

const EditMemberForm: FunctionComponent<IEditMemberFormProps> = ({ member, company, toggleForm }) => {
    const extractAdminBoolean = (adminStatus: string | boolean |undefined) => {
        if (adminStatus === 'Admin') return true;
        else if (typeof adminStatus === 'boolean') return adminStatus;
        else return false;
    }
    
    const { updateNotification } = useDashboard()

    const { 
        register, 
        handleSubmit, 
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: member?.userInfo.name,
            address: member?.userInfo?.address,
            city: member?.userInfo?.city,
            state: member?.userInfo?.state,
            zip: member?.userInfo?.zip,
            dob: member?.userInfo?.dob,
            phone: member?.userInfo.phone,
            admin: [extractAdminBoolean(member?.metadata.isAdmin) ? 'Admin' : false]
        },
        resolver: yupResolver(EditMemberSchema)
    });
    
    const submit = async (values: any) => {
        if (!member) return;
        const adminStatus = extractAdminBoolean(values.admin[0]);
        
        try {
            member.userInfo.name = values.name;
            member.userInfo.address = values.address;
            member.userInfo.city = values.city;
            member.userInfo.state = values.state;
            member.userInfo.zip = values.zip;
            member.userInfo.dob = values.dob;
            member.userInfo.phone = values.phone;
            member.metadata.isAdmin = adminStatus;
            member.metadata.accessLevel = adminStatus ? ACCESS_LEVEL.COMPANY : ACCESS_LEVEL.MEMBER;

            const result = await memberHelpers.updateMember(member);

            if (result.status === 200) {
                updateNotification({
                    message: 'Update Successful',
                    theme: NotificationThemes.SUCCESS
                });
            }
        } catch (error) {
            updateNotification({
                    message: 'Update Failed',
                    theme: NotificationThemes.FAIL
                });
        } finally {
            toggleForm();
        }
    }

    if(!member) return <p>Loading</p>
    
    return (
        <>
        <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
            <h4 className="text-2xl">Edit Member Information</h4>
            
            <div className="mb-2">
                <Button text="Cancel" onClick={toggleForm} theme={BUTTON_THEMES.INVERSE} styles="mr-4"/>
                <Button text="Save" onClick={handleSubmit(submit)} theme={BUTTON_THEMES.PRIMARY} cyId="save-button" />    
            </div>
        </div>
        <form className="text-md flex align-center">
            <div className="mr-10">
                <TextInput
                    label="Name"
                    id='name'    
                    placeholder={member.userInfo.name}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"  
                    error={errors.name?.message}  
                    dataCyId="name-input"  
                />
                
                <p className="mb-3"><span className="font-medium mr-3">Email:</span> {member?.userInfo?.email}</p>    
            
                <TextInput
                    label="Phone"
                    id='phone'    
                    placeholder={member.userInfo.phone}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2" 
                    error={errors.phone?.message}
                    dataCyId="phone-input"     
                />

                <TextInput
                    label="dob"
                    id='dob'    
                    placeholder={member.userInfo.dob || ''}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.dob?.message} 
                    dataCyId="dob-input"   
                />    
            </div>
            <div className="mr-10">
                <TextInput
                    label="Address"
                    id='address'    
                    placeholder={member.userInfo.name}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"  
                    error={errors.address?.message}
                    dataCyId="address-input"     
                />
                <TextInput
                    label="City"
                    id='city'    
                    placeholder={member.userInfo.city}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2" 
                    error={errors.city?.message}
                    dataCyId="city-input"  

                />

                <TextInput
                    label="State"
                    id='state'    
                    placeholder={member?.userInfo?.state}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.state?.message}  
                    dataCyId="state-input"  
                />    

                <TextInput
                    label="Zip"
                    id='zip'    
                    placeholder={member?.userInfo?.zip}
                    register={register}  
                    containerStyles="flex align-center mb-3"   
                    labelStyles=" mr-5 text-lg mt-2"   
                    error={errors.zip?.message} 
                    dataCyId="zip-input"    
                />        
            </div>    
            <div>
                <p className="mb-3"><span className="font-medium">Account Creation:</span> {member?.metadata?.createdAt}</p>
                <p className="mb-3"><span className="font-medium">Last Update:</span> {member?.metadata?.updatedAt}</p>
                <Checkbox labelText="Admin" register={register} containerStyles="!justify-start" labelStyles="ml-3 mb-1" />
                {company && <p className="mb-3"><span className="font-medium">Invited By:</span> {company}</p>}
            </div>    
        </form>
        </>
    )
}

export default EditMemberForm;