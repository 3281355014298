import { FunctionComponent } from 'react';
import _ from 'lodash';

interface ITextInputProps {
    id: string;
    placeholder: string;
    onChange?: (value: string) => void;
    label?: string;
    containerStyles?: string;
    labelStyles?: string;
    password?: boolean;
    testId?: string;
    dataCyId?: string;
    register?: Function;
    error?: string;
    onKeyUp?: Function;
}

const TextInput: FunctionComponent<ITextInputProps> = ({
    label, 
    id, 
    onChange, 
    placeholder, 
    containerStyles,
    labelStyles,
    password,
    testId,
    dataCyId,
    register,
    error,
    onKeyUp
}) => (
    register ? (
        <div className={`${containerStyles}`}>
            {
                label && (
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${labelStyles}`}>
                        {label}
                    </label>
                )
            }
            <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary mb-2'
                id={id}
                type={password ? 'password' : 'text'}
                autoComplete={password ? 'current-password' : ''}
                placeholder={placeholder}
                onChange={(event) => onChange ? onChange(event.target.value) : console.log(event.target)}
                onKeyUp={onKeyUp}
                data-testid={testId}
                data-cy={dataCyId}
                {
                    ...register(_.camelCase(label?.replaceAll(' ', '')))
                }
            />
            {error && <p className='text-sm text-red-600 mb-3'>{ error }</p>}
        </div>
    ) : (
        <div className={`${containerStyles}`}>
            {
                label && (
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        {label}
                    </label>
                )
            }
            <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary mb-4'
                id={id}
                type={password ? 'password' : 'text'}
                autoComplete={password ? 'current-password' : ''}
                placeholder={placeholder}
                onChange={(event) => onChange ? onChange(event.target.value) : console.log(event.target)}
                //@ts-ignore
                onKeyUp={onKeyUp}
                data-testid={testId}
                data-cy={dataCyId}
            />
        </div>
    )
);

export default TextInput;