import axios from "axios";
import { AuthRegisterForm } from "../../constants/companies";
import { ACCESS_LEVEL } from "../../constants";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const generateSubscriberApplication: (data: AuthRegisterForm, userId: string) => Record<any, any> = (data, userId) => {
    return {
        applicationId: userId,
        applicationType: data.accountType,
        applicationFormData: {
            name: data.name,
            address: data.address,
            city: data.city,
            state: data.state,
            country: data.country,
            zip: data.zip,
            phone: data.phone,
            email: data.email,
            ein: data.ein,
            reasonForApplication: data.reasonForApplication,
            refferedBy: data.referredBy,
            agreedToTerms: data.agreedToTerms,
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            applicationStart: new Date().toLocaleString(), // TODO: Update at start of application
            applicationSubmit: new Date().toLocaleString(),
            browserUsed: 'chrome', //TODO: update to be dynamic
            reApply: false, // TODO: Update after launch
            accessLevel: ACCESS_LEVEL.COMPANY
        }
    }
}

const newSubscriberApplication: (data: AuthRegisterForm, userId: string) => Object = async (data, userId) => {
    const application = generateSubscriberApplication(data, userId);

    try {
        const result = await axios.post(`${BASE_URL}/subscribers/apply`, application);
   
        return result;
    } catch (error) {
        throw error;
    }
}

const getSubscriberApplications = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/subscribers/manager-applications`);
  
        return result?.data?.result?.Items;
    } catch (error) {
        throw error;
    }   
}

const getSubscriberApplicationById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/subscribers/manager-applications/${id}`);
    
        return result?.data?.result?.Items[0];
    } catch (error) {
        throw error;
    }   
}

const approveSubscriberApplication = async (subscriber: Record<any, any>) => {
    try {
        const result = await axios.post(`${BASE_URL}/subscribers/approve`, subscriber);

        return result
    } catch (error) {
        throw error;
    }
}

export default {
    generateSubscriberApplication,
    newSubscriberApplication,
    getSubscriberApplicationById,
    getSubscriberApplications,
    approveSubscriberApplication
}