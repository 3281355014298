import { useForm } from "react-hook-form";
import { TextInput } from "../inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDashboard } from "../../hooks/useDashboard";
import { addCreditUser } from "../../utils/SoftPullAPI";
import { IAddManagerForm } from "../../constants/managers";
import { NotificationThemes } from "../../context/DashboardContext";
import { FunctionComponent, MouseEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { managerHelpers } from "../../utils/ClientVitalsAPI";
import { registerAuthUser, markEmailAsVerified } from "../../utils/Cognito";
import DashboardPageContainer from "../DashboardPageContainer";
import AddManagerSchema from "./ValidationScehma/AddManagerSchema";
import PageTitle from "../PageTitle";
import Button from "../Button";
import convert from 'xml-js';
import axios from "axios";

const ManagerInvitationForm: FunctionComponent = () => {
    const [showVerify, setShowVerify] = useState<boolean>(false);
    const [params] = useSearchParams();
    const { updateNotification } = useDashboard();
    const navigate = useNavigate();

    const i = params.get('i') || '';
    const baseName = params.get('name');
    const email = params.get('email');

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: baseName || '',
            email: email || '',
            i: i || '',
            password: ''
        },
        resolver: yupResolver(AddManagerSchema)
    });

    const submit = async (values: IAddManagerForm) => {
        try {
            const authResult = await registerAuthUser(values.email, values.password);
            if (authResult?.UserSub) {
                const newManager = managerHelpers.formatManager(values, i);
                newManager.userId = authResult?.UserSub;
                const ipResult = await axios.get('https://api.ipify.org?format=json');

                const result = await managerHelpers.addManager(newManager);
                
                const cbcResult = await addCreditUser({
                    uid: result.data.result.managerId,
                    name: newManager.userInfo.name,
                    email: newManager.userInfo.email,
                    password: values.password,
                    ip: ipResult.data.ip
                });
                const result2 = convert.xml2js(cbcResult, { compact: true });
             
                newManager.userId = result.data.result.managerId;

                //@ts-ignore
                newManager.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
                
                await managerHelpers.addManager(newManager);
                await markEmailAsVerified(values.email);
                navigate('/login');
            }
        } catch (error: unknown) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Submit Form'
            });
        }
    }

    return (
        <DashboardPageContainer>
            <PageTitle title="Complete Form to Complete Onboarding" />

            <form className="max-w-screen grid grid-cols-2 gap-1">
                    <TextInput 
                        id='name'
                        label='name'
                        placeholder='Name...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.name?.message}
                    />
                    <TextInput 
                        id='email'
                        label='email'
                        placeholder='Email...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.email?.message}            
                    />
                    <TextInput 
                        id='phone'
                        label='phone'
                        placeholder='Phone...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.phone?.message}   
                    />   
                    <TextInput 
                        id='password'
                        label='password'
                        placeholder='Password...'
                        register={register}
                        containerStyles='my-0 p-0'
                        password
                        error={errors.password?.message}   
                    />            
                <Button
                    text={'Submit'}
                    onClick={handleSubmit(submit)}
                    styles='w-[300px] mt-12'
                />
            </form>
        </DashboardPageContainer>
    )
}

export default ManagerInvitationForm;