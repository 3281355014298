import { FunctionComponent } from "react";
import WideTile from "../tiles/WideTile";

interface ICreditApplicationInfoProps {
    application: Record<any, any>
}

const CreditApplicationInfo: FunctionComponent<ICreditApplicationInfoProps> = ({ application }) => (
    <WideTile>
        <div>
            <p><span className="font-semibold">Name:</span> {application.applicationFormData.name}</p>
            <p><span className="font-semibold">Email:</span> {application.applicationFormData.email}</p>
            <p><span className="font-semibold">Phone:</span> {application.applicationFormData.phone}</p>
            <p><span className="font-semibold">Date of Birth:</span> {application.applicationFormData.dob}</p>
            <p><span className="font-semibold">Address:</span> {application.applicationFormData.address}</p>
            <p><span className="font-semibold">City:</span> {application.applicationFormData.city}</p>
            <p><span className="font-semibold">State:</span> {application.applicationFormData.state}</p>
            <p><span className="font-semibold">Zip:</span> {application.applicationFormData.zip}</p>
            <p><span className="font-semibold">State:</span> {application.applicationFormData.state}</p>
            <p className="text-success"><span className="font-semibold">Consent Given:</span> {application.applicationFormData.consent}</p>
        </div>
    </WideTile>
);

export default CreditApplicationInfo;