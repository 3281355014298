import { FunctionComponent, useState } from "react";
import { ACCESS_LEVEL } from "../../constants";
import { useUser } from "../../hooks/useUser";
import TextInput from "./TextInput";

const SetScoreThreshold: FunctionComponent = () => {
    const { user } = useUser();
    //@ts-ignore
    const [ newThresHold, setNewThresHold ] = useState<string>(user?.metadata?.thresHold || '680')

    const handleChange = (value:string) => setNewThresHold(value);

    const handleThresHoldSubmit = async (event:any) => {
        if ((event.key === 'Enter' || event.keyCode === 13)) {
             if (Number.isNaN(newThresHold)){
                  return false
             }
             //@ts-ignore
             user.metadata.thresHold = newThresHold
             try {
                  //@ts-ignore
                  const result = await companyHelpers.updateCompany(user)
             } catch (error){
                  console.log(error)
             }
        }
   }

    return (
        <div className="flex flex-row gap-2 mb-2">
            <p>
                <span className="font-semibold">Credit Score Threshold:</span>
            </p>
            {user.metadata.accessLevel === ACCESS_LEVEL.COMPANY ? (
                <TextInput
                    id='text'
                    labelStyles="border border-gray-300"
                    placeholder="680"
                    onChange={handleChange}
                    onKeyUp={handleThresHoldSubmit}
                />
            ) : (
                <p>680</p>
            )}
    </div>
    )
}

export default SetScoreThreshold;