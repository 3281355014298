import axios from "axios";
import { ACCESS_LEVEL, UserTypes } from "../../constants";
import { Manager, IAddManagerForm } from "../../constants/managers";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const getManagers = async (accessLevel: ACCESS_LEVEL) => {
    if (accessLevel !== ACCESS_LEVEL.OWNER) return [];
    try {
        const link = `${BASE_URL}/managers/${accessLevel}`
       
        const result = await axios.get(link);

        return result?.data?.result?.Items
    } catch (error) {
        throw error;
    }
}

const addManager = async (manager: Manager) => {
    try {
        const result = await axios.post(`${BASE_URL}/managers/add`, manager);

        return result
    } catch (error) {
        throw error;
    }
}

const formatManager: (data: IAddManagerForm, i: string) => Manager = (data, i) => {
    return {
        userId: '',
        userType: UserTypes.MANAGER,
        userInfo: {
            name: data.name,
            phone: data.phone,
            email: data.email,
            owner: i
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            browserUsed: 'chrome',
            accessLevel: ACCESS_LEVEL.CVA,
        }
    }
}

export default {
    getManagers,
    addManager,
    formatManager,
}