import { FunctionComponent, ReactNode } from "react";


interface IReportCardProps {
    children: ReactNode;
    wide?: boolean
}

const ReportCard: FunctionComponent<IReportCardProps> = ({children, wide}) => (
    <div className={`border border-primary py-2 px-4 w-[600px] mb-3` + (wide ? ` w-full` : '')}>
        {children}
    </div>
);

export default ReportCard