import { FunctionComponent,  useEffect } from "react";
import Button from "../../Button";
import { TextInput } from "../../inputs";
import { BUTTON_THEMES } from "../../Button";
import { useForm } from "react-hook-form";
import { IInviteMemberForm } from "../../../constants/members";
import { useUser } from "../../../hooks/useUser";
import { sendgridHelpers } from "../../../utils/ClientVitalsAPI";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";


interface IAddManagerFormProps {
    onCancel: () => void;
} 

const AddManagerForm: FunctionComponent<IAddManagerFormProps> = ({ onCancel }) => {
    const { register, handleSubmit } = useForm<IInviteMemberForm>();
    const { user: { userId, metadata: { accessLevel } } } = useUser();
    const { updateNotification } = useDashboard();

    const submit = async (values: IInviteMemberForm) => {
        const payload = {
            name: values.name,
            email: values.email,
            i: userId,
            formLink: `${process.env.REACT_APP_URL_BASE}/manager-invitation?i=${userId}&name=${values.name.split(' ').join('-')}&email=${values.email}`
        }

        try {
            const result = await sendgridHelpers.sendManagerEmail(payload, accessLevel);

            //@ts-ignore
            if (result?.status === 202) {
                updateNotification({
                    message: 'Manager Email Sent',
                    theme: NotificationThemes.SUCCESS
                })
            }
        } catch (error) {
            updateNotification({
                message: 'Manager Email Failed To Send',
                theme: NotificationThemes.FAIL
            });
        } finally {
            onCancel();
        }
    }

    return (
        <form>
            <TextInput
                label="Name"
                id="name"
                placeholder="Enter manager's name..."
                register={register}
                dataCyId="name"
            />

            <TextInput
                label="Email"
                id="email"
                placeholder="Enter manager's email..."
                register={register}
                dataCyId="email"
            />

            <div className="w-[100%] flex items-center justify-between mt-3">
                <Button text="Cancel" theme={BUTTON_THEMES.INVERSE} onClick={onCancel} />
                <Button text="Send" onClick={handleSubmit(submit)} />
            </div>
        </form>
    )
}

export default AddManagerForm;